import React from "react";
import loadable from "@loadable/component";

import type { CommunityPost } from "types/CommunityPost";

import { Link } from "react-router-dom";

import routeHelper from "i18n/routeHelper";
import styles from "./CommunityPostCard.module.scss";
import { goalHubCommunityPostClick } from "helpers/goalTracking";

const Avatar: any = loadable(
  () =>
    import(/* webpackChunkName: "components-Avatar"              */ "../Avatar")
);

export interface Props {
  post: CommunityPost;
}

function CommunityPostCard({
  post: { locale, title, excerpt, author, likeCount, commentCount, uid, slug }
}: Props): JSX.Element {
  return (
    <Link
      onClick={() => goalHubCommunityPostClick()}
      to={routeHelper[locale || "en"].communityPost(uid, slug)}
      className={styles.wrapper}
    >
      <div data-testid="community-post-card">
        <header>
          <h3>{title}</h3>
        </header>

        <div>
          <p>{excerpt}</p>
        </div>
        <footer>
          <Avatar
            uid={author.uid}
            displayName={author.displayName}
            size="small"
            url={author.avatarUrl}
            className={styles.avatar}
          />
          {likeCount > 0 && <div className={styles.likeCount}>{likeCount}</div>}
          {commentCount > 0 && (
            <div className={styles.commentCount}>{commentCount}</div>
          )}
        </footer>
      </div>
    </Link>
  );
}

export default CommunityPostCard;
